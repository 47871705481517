export interface Team {
  name: string
  id: number
  location: string
  notificationsEnabled?: boolean
  companyIdentifier?: string
  players?: []
  members?: []
  locations?: []
}

enum TrackGameSalesBy {
  Location = 'location',
  Members = 'members',
}

export interface GameTeam {
  name: string
  id: number
  trackGameSalesBy: TrackGameSalesBy
}

export interface SelectableTeam {
  id: number
  name: string
}
